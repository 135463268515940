import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import ContentShell from './Content/ContentShell'
import BreadcrumbsShell from './Breadcrumbs/BreadcrumbsShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import GalleryShell from './Gallery/GalleryShell'
import OverviewShell from './Overview/OverviewShell'
import Search from './Search'
import Googlemaps from './Googlemaps'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: ContentShell,
    [`${prefix}_Flex_Breadcrumbs`]: BreadcrumbsShell,
    [`${prefix}_Flex_Gallery`]: GalleryShell,
    [`${prefix}_Flex_Overview`]: OverviewShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Search`]: Search,
    [`${prefix}_Flex_Googlemaps`]: Googlemaps,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  return <LosseFlex flex={flex as any} flexers={flexers as any} />
}
